import React from "react";
import covidthumb from '../../../assets/covid-thumb.png';
import covid from '../../../assets/covid-19_au.png';

const PortfolioItem1 = () => {
    return(
        <div>
            <section className="intro portfolio-item__intro">
                <h1 className="section__title section__title--intro">
                This is <strong>covid-19 au</strong>
                </h1>
                <p className="section__subtitle section__subtitle--intro">The Global Covid Cases Tracker, for Australians</p>
                <img className="intro__img" src={covidthumb} alt=""></img>
            </section>
            <div className="portfolio-item-individual">
                <p>
                    Covid-19 au was set up back in 2020 when like-minded students and volunteers came together under the supervision of Dr Chunyang Chen of
                    the Monash Faculty of Information Systems. 
                </p>
                <p>
                    This dashboard was developed to provide researchers, public health authorities and the general Australian public, with a user-friendly 
                    tool to track the COVID-19 pandemic as it unfolds.
                </p>
                <img src={covid} alt="" className='portfolio-item-image'></img>
                <p>
                    My involvement included parsing and analysing global datasets to create graphs, tables and figures for users to see how the global pandemic was affecting 
                    countries around the world.
                </p>
                <p>
                    I also worked on the UI, and handled all navigation on the website. As well as the creation of the about-us, blog and information page, which provided
                    users with up-to-date relevant information ranging from news to useful advice.
                </p>
                <p>Click <a href="https://covid-19-au.com/" target="_blank" rel="noopener noreferrer">here</a> to check us out!</p>
            </div>
        </div>
    )
}

export default PortfolioItem1;