import React from "react";

// Header
import Logo from "../Logo/Logo.js";
import NavTog from '../NavTog/NavTog';
import Nav from '../Nav/Nav';

const Header = (props) => {

    const toggleNav = () => {
        document.body.classList.toggle('nav-open')
    }
    return(
        <header>
            <Logo updateSection={props.updateSection}/>
            <NavTog ToggleNav={toggleNav} />
            <Nav ToggleNav={toggleNav} updateSection={props.updateSection}/>
        </header>
    )
}

export default Header;