import React from 'react';
import PortfolioItem1 from '../Sections/PortfolioItem/PortfolioItem1';
import PortfolioItem2 from '../Sections/PortfolioItem/PortfolioItem2';
import PortfolioItem3 from '../Sections/PortfolioItem/PortfolioItem3';

const IndPortItems = (props) => {
    return(
        <div>
            {props.currSection == 1 ? <PortfolioItem1 /> : ''}
            {props.currSection == 2 ? <PortfolioItem2 /> : ''}
            {props.currSection == 3 ? <PortfolioItem3 /> : ''}
        </div>
    )
}

export default IndPortItems;