import React from "react";
import covid_19 from "../../../assets/covid-thumb.png";
import StuMe from "../../../assets/StuMe_Thumb.png";
import Origins from "../../../assets/Origins_Thumb.png";

const Work = (props) => {
    return(
        <section className="my-work" id="work">
            <h2 className="section__titl section__title--work">My Work</h2>
            <p className="section__subtitle section__subtitle--work">A selection of my range of work</p>
            
            <div className="portfolio">
                {/* Portfolio Item #1 */}
                <a href="#" className="portfolio__item">
                    <img src={covid_19} alt="" className="portfolio__img" onClick={() => props.updateSection(1)}/>
                </a>

                {/* Portfolio Item #2 */}
                <a href="#" className="portfolio__item">
                    <img src={StuMe} alt="" className="portfolio__img" onClick={() => props.updateSection(2)}/>
                </a>

                {/* Portfolio Item #3 */}
                <a href="#" className="portfolio__item">
                    <img src={Origins} alt="" className="portfolio__img" onClick={() => props.updateSection(3)}/>
                </a>
            </div>

        </section>
    )
}

export default Work;