import './App.css';
import {React, useState} from 'react';

// Import Components
import Header from './Components/Headers/Header/Header';
import Sections from './Components/Sections/Sections';
import IndPortItems from './Components/IndPortItems/IndPortItems';
import Footer from './Components/Footer/Footer';

function App() {
    const [currSection, setSection] = useState(0);

    const updateSection = sectionId => {
        setSection(sectionId);
    }

    return (
        <div className="App">
            <Header updateSection={updateSection}/>
            {currSection == 0 ? <Sections updateSection={updateSection}/> : <IndPortItems currSection={currSection}/>}
            <Footer />
        </div>
    );
}

export default App;
