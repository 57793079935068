import React from "react";
import origins from '../../../assets/Origins.png';
import originsThumb from "../../../assets/Origins_Thumb.png";

const PortfolioItem3 = () => {
    return(
        <div>
            <section className="intro portfolio-item__intro">
                <h1 className="section__title section__title--intro">
                This is <strong>Origins</strong>
                </h1>
                <p className="section__subtitle section__subtitle--intro">Keep the heritage of your family alive</p>
                <img className="intro__img" src={originsThumb} alt=""></img>
            </section>
            <div className="portfolio-item-individual">
                <p>Origins was my capstone University project, which involved creating a website to store artifacts for our client to remember the heritage passed down in their family.</p>
                <p>We gathered requirements from our client, such as localisation to chinese, making posts with image upload to save artefacts, and registration to only allow authorised users to create/edit artifacts.</p>
                <img src={origins} alt="" className='portfolio-item-image'></img>
                <p>The website was built on MERN stack, with my invovlement centering around the landing page with the carousel, and the image upload with posts and the gallery page.</p>
                <p>Click <a href="http://static-family.herokuapp.com/" target="_blank" rel="noopener noreferrer">here</a> to check it out!</p>
            </div>
        </div>
    )
}

export default PortfolioItem3;