import React from "react";
import prof from "../../../assets/profile_pic.JPG";

const Home = () => {
    return(
    <section className="intro" id="home">
        <h1 className="section__title section__title--intro">
        Hi, I am <strong>Andrew Zhang</strong>
        </h1>
        <p className="section__subtitle section__subtitle--intro">software dev</p>
        <img className="intro__img" src={prof} alt=""></img>
    </section>
  )
}

export default Home;