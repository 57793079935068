import React from "react";

import Home from "./Home/Home";
import Services from "./Services/Services";
import Skills from "./Skills/Skills";
import About from "./About/About";
import Work from "./Work/Work";

const Sections = (props) => {
    return(
        <div>
            <Home />
            {/* <Services /> */}
            <Skills />
            <About />
            <Work updateSection={props.updateSection}/>
        </div>
  )
}

export default Sections;