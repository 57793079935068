import React from "react";

const NavTog = (props) => {
    return(
        <button onClick={props.ToggleNav} className="nav-toggle" aria-label="toggle navigation">
            <span className="hamburger"></span>
        </button>
    )
}

export default NavTog;