import React from "react";

// HOW TO OPEN A LINK IN A NEW TAB:
// The target attribute set to _blank, which tells the browser to open the link in a new tab/window, depending on the browser's settings
// The rel attribute set to noreferrer noopener to prevent possible malicious attacks from the pages you link to.

const Footer = () => {
    return(
        <footer className="footer">
            <a href="mailto:andrew.zhang9799@gmail.com" className="footer__link">andrew.zhang9799@gmail.com</a>
            <ul className="social-list">
                <li className="social-list__item">
                    <a href="https://github.com/andrewzhang3" className="social-list__link" target="_blank" rel="noopener noreferrer">
                        <i className="fa-brands fa-github" />
                    </a>
                </li>
                <li className="social-list__item">
                    <a href="https://www.linkedin.com/in/andrewzhang9799/" className="social-list__link" target="_blank" rel="noopener noreferrer">
                        <i className="fa-brands fa-linkedin" />
                    </a>
                </li>
            </ul>
        </footer>
    )
}

export default Footer;