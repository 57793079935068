import React from "react";
import StuMe from '../../../assets/StuMe.png';
import StuMeThumb from "../../../assets/StuMe_Thumb.png";

const PortfolioItem2 = () => {
    return(
        <div>
            <section className="intro portfolio-item__intro">
                <h1 className="section__title section__title--intro">
                This is <strong>StuMe</strong>
                </h1>
                <p className="section__subtitle section__subtitle--intro">The Student Meetup Hub</p>
                <img className="intro__img" src={StuMeThumb} alt=""></img>
            </section>
            <div className="portfolio-item-individual">
                <p>StuMe was created as my first university project in which we aimed to create a platform for students to be able see what events are going on around the campus.</p>
                <p>The site would rely on students to make posts about events happening around them, or schedule for events to start, and other students could look at the map and see what would be happening on Campus for them to explore.</p>
                <img src={StuMe} alt="" className='portfolio-item-image'></img>
                <p>My role in this project included the authorisation, user profile, event creation, map annotation and event gallery pages, as well as the design and general work throughout. The site was
                    created with HTML, CSS, Javascript and Node.
                </p>
                <p>Click <a href="http://stume.herokuapp.com/" target="_blank" rel="noopener noreferrer">here</a> to check it out!</p>
            </div>
        </div>
    )
}

export default PortfolioItem2;