import React from "react";
import prof from "../../../assets/profile_pic.JPG";

const About = () => {
    return(
        <section className="about-me" id="about">
            <h2 className="section__title section__title--about">Who I Am</h2>
            <p className="section__subtitle section__subtitle--about">A Passionate Developer based in Melbourne</p>
            
            <div className="about-me__body">
                <p>
                    I am a software developer with a passion to create and eagerness to learn. I studied a Bachelor of Science at the University of Melbourne
                    Majoring in Computer Science, where I graduated at the end of 2020. 
                </p>
                <p>
                    My work experience includes a 1 Year internship at NAB during my studies, followed by a full-time position as a Software Developer at NAB.
                    I have also been involved in some volunteer work with the Monash Faculty of Information Systems to create a real-time tracker for statistics
                    regarding covid-19.
                </p>
            </div>

            <img className="about-me__img" src={prof} alt=""></img>
        </section>
    )
}

export default About;