import React from 'react';

const Skills = () => {
    return(
        <section className="my-services" id="skills">
        <h2 className="section__title section__title--services">My Skills</h2>
        <div className="services">
            <div className="service">
                <strong><i className="fa-brands fa-html5"></i> HTML</strong>
            </div>
            <div className="service">
                <strong><i className="fa-brands fa-css3"></i> CSS</strong>
            </div>
            <div className="service">
                <strong><i className="fa-brands fa-js-square"></i> JavaScript</strong>
            </div>
            <div className="service">
                <strong><i className="fa-brands fa-react"></i> React</strong>
            </div>
            <div className="service">
                <strong><i className="fa-brands fa-python"></i> Python</strong>
            </div>
            <div className="service">
                <strong><i className="fa-brands fa-salesforce"></i> Salesforce</strong>
            </div>
            <div className="service">
                <strong><i className="fa-brands fa-aws"></i> AWS</strong>
            </div>
            <div className="service">
                <strong><i className="fa-brands fa-r-project"></i> R</strong>
            </div>
            <div className="service">
                <strong><i className="fa-brands fa-github"></i> GitHub</strong>
            </div>
        </div>
        <a href="#work" className="btn">My Work</a>
    </section>
    )
}

export default Skills;