import React from "react";

const Nav = (props) => {
    return(<nav className="nav">
        <ul onClick={props.ToggleNav} className="nav__list">
            <li className="nav__item">
                <a href="#home" className="nav__link" onClick={() => props.updateSection(0)}>Home</a>
            </li>
            <li className="nav__item">
                <a href="#skills" className="nav__link" onClick={() => props.updateSection(0)}>My Skills</a>
            </li>
            <li className="nav__item">
                <a href="#about" className="nav__link" onClick={() => props.updateSection(0)}>About Me</a>
            </li>
            <li className="nav__item">
                <a href="#work" className="nav__link" onClick={() => props.updateSection(0)}>My Work</a>
            </li>
        </ul>
    </nav>)
}

export default Nav;